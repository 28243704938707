import { useSearchParams } from 'react-router-dom';
import { Mail, Phone, ExternalLink } from 'lucide-react';
import { Helmet } from 'react-helmet-async';

export const AddClinic = () => {
  const [searchParams] = useSearchParams();
  const fromArticle = searchParams.get('from');

  return (
    <>
      <Helmet>
        <title>Voeg Uw Kliniek Toe | Baardtransplantatie.nl</title>
        <meta 
          name="description" 
          content="Registreer uw baardtransplantatie kliniek op Baardtransplantatie.nl en bereik meer potentiële klanten."
        />
      </Helmet>

      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="bg-white rounded-lg shadow-lg p-8">
          <div className="text-center mb-8">
            <h1 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-2 sm:mb-4">
              Voeg Uw Kliniek Toe aan Baardtransplantatie.nl
            </h1>
            <p className="text-base sm:text-lg text-gray-600 px-2">
              Word onderdeel van het grootste platform voor baardtransplantatie klinieken in Nederland
            </p>
            {fromArticle && (
              <p className="mt-2 text-sm sm:text-base text-blue-600">
                U komt van ons artikel over baardtransplantatie
              </p>
            )}
          </div>

          <div className="prose max-w-none mb-8">
            <p className="text-sm sm:text-base text-gray-700 mb-4">
              Wilt u uw kliniek toevoegen aan ons platform? Baardtransplantatie.nl biedt u de mogelijkheid om uw diensten te presenteren aan duizenden potentiële klanten die op zoek zijn naar baardtransplantatie specialisten.
            </p>
            
            <h2 className="text-lg sm:text-xl font-semibold mb-4">Wat bieden wij?</h2>
            <ul className="list-disc pl-4 sm:pl-6 mb-6 text-sm sm:text-base text-gray-700">
              <li>Uitgebreide bedrijfspagina met al uw informatie</li>
              <li>Zichtbaarheid in zoekresultaten per stad</li>
              <li>Direct contact met geïnteresseerde klanten</li>
              <li>Professionele presentatie van uw diensten</li>
            </ul>

            <p className="text-sm sm:text-base text-gray-700 mb-6">
              De jaarlijkse bijdrage wordt individueel besproken op basis van uw wensen en de mogelijkheden die wij kunnen bieden voor uw kliniek.
            </p>
          </div>

          <div className="grid md:grid-cols-2 gap-6 mb-8">
            <a
              href="https://forms.google.com/baardtransplant-registratie"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              <ExternalLink className="w-5 h-5 mr-2" />
              Registreer Uw Kliniek
            </a>
            
            <a
              href="mailto:info@baardtransplantatie.nl"
              className="flex items-center justify-center px-6 py-3 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
            >
              <Mail className="w-5 h-5 mr-2" />
              Email Ons
            </a>
          </div>

          <div className="border-t pt-6">
            <h3 className="text-lg font-semibold mb-4">Direct Contact</h3>
            <div className="space-y-3">
              <div className="flex items-center text-gray-700">
                <Phone className="w-5 h-5 mr-3 text-blue-600" />
                <span>+31658924427</span>
              </div>
              <div className="flex items-center text-gray-700">
                <Mail className="w-5 h-5 mr-3 text-blue-600" />
                <span>info@baardtransplantatie.nl</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};