import { Link } from 'react-router-dom';
import { ImageWithFallback } from './ImageWithFallback';
import { Article } from '../types/Article';

interface BlogCardProps {
  article: Article;
}

export const BlogCard = ({ article }: BlogCardProps) => {
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <div className="h-48 bg-gray-200">
        <ImageWithFallback
          src={article.images.card}
          alt={article.title}
          className="w-full h-full object-cover"
        />
      </div>
      <div className="p-6">
        <h3 className="text-xl font-semibold mb-2">{article.title}</h3>
        <p className="text-gray-600 mb-4">{article.excerpt}</p>
        <div className="flex justify-between items-center text-sm text-gray-500">
          <span>{article.date}</span>
          <span>{article.readTime} min leestijd</span>
        </div>
        <Link 
          to={`/blog/${article.slug}`}
          className="block w-full bg-blue-600 text-white text-center py-2 px-4 rounded-md hover:bg-blue-700 transition-colors mt-4"
        >
          Lees meer
        </Link>
      </div>
    </div>
  );
}; 