import { SEO } from '../components/SEO';
import { Breadcrumbs } from '../components/Breadcrumbs';

export const Voorwaarden = () => {
  return (
    <>
      <SEO 
        title="Algemene Voorwaarden | Baardtransplantatie.nl"
        description="Lees onze algemene voorwaarden voor het gebruik van Baardtransplantatie.nl"
        canonical="/voorwaarden"
      />

      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12">
        <Breadcrumbs />
        
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Algemene Voorwaarden</h1>

        <div className="prose prose-lg max-w-none">
          <p className="text-lg text-gray-600 mb-8">
            Laatst bijgewerkt: {new Date().toLocaleDateString('nl-NL')}
          </p>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">1. Algemeen</h2>
            <p>
              Deze algemene voorwaarden zijn van toepassing op het gebruik van de website Baardtransplantatie.nl. 
              Door gebruik te maken van onze website gaat u akkoord met deze voorwaarden.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">2. Dienstverlening</h2>
            <p>
              Baardtransplantatie.nl is een informatief platform dat:
            </p>
            <ul className="list-disc pl-6 mt-4">
              <li>Informatie verstrekt over baardtransplantaties</li>
              <li>Een overzicht biedt van klinieken die baardtransplantaties uitvoeren</li>
              <li>Gebruikers helpt bij het maken van een geïnformeerde keuze</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">3. Aansprakelijkheid</h2>
            <p>
              Hoewel wij streven naar accurate en actuele informatie, kunnen wij niet garanderen dat alle informatie op onze website volledig en foutloos is. Wij zijn niet aansprakelijk voor:
            </p>
            <ul className="list-disc pl-6 mt-4">
              <li>Eventuele fouten of onvolledigheden in de informatie</li>
              <li>Schade die voortvloeit uit het gebruik van onze website</li>
              <li>De dienstverlening van de vermelde klinieken</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">4. Intellectueel Eigendom</h2>
            <p>
              Alle content op Baardtransplantatie.nl, waaronder teksten, afbeeldingen, logo's en video's, is ons eigendom of dat van onze partners. Het is niet toegestaan om zonder onze toestemming content van de website te kopiëren of te verspreiden.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">5. Klinieken en Reviews</h2>
            <p>
              Voor klinieken die op ons platform worden vermeld, geldt dat:
            </p>
            <ul className="list-disc pl-6 mt-4">
              <li>Zij verantwoordelijk zijn voor de juistheid van hun informatie</li>
              <li>Wij het recht hebben om vermeldingen te weigeren of te verwijderen</li>
              <li>Reviews moeten voldoen aan onze richtlijnen voor eerlijke beoordelingen</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">6. Wijzigingen</h2>
            <p>
              Wij behouden ons het recht voor om deze voorwaarden te wijzigen. Wijzigingen worden op deze pagina gepubliceerd. Door gebruik te blijven maken van onze website gaat u akkoord met eventuele wijzigingen.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">7. Contact</h2>
            <p>
              Voor vragen over onze voorwaarden kunt u contact opnemen via:
            </p>
            <ul className="list-none mt-4">
              <li>Email: info@baardtransplantatie.nl</li>
              <li>Telefoon: +31658924427</li>
            </ul>
          </section>
        </div>
      </div>
    </>
  );
}; 