import { Article } from '../types/Article';
import { clinic as nielsenClinic } from '../clinics/nielsen-haarkliniek';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

interface BlogPostProps {
  article: Article;
}

export const BlogPost = ({ article }: BlogPostProps) => {
  const randomClinics = useMemo(() => {
    const allClinics = [nielsenClinic];
    return [...allClinics].sort(() => Math.random() - 0.5).slice(0, 3);
  }, []);

  return (
    <>
      <Helmet>
        <title>{article.title} | Baardtransplantatie</title>
        <meta name="description" content={article.excerpt} />
        <meta property="og:title" content={article.title} />
        <meta property="og:description" content={article.excerpt} />
        <meta property="og:image" content={article.images.banner} />
        <meta property="og:type" content="article" />
        <meta name="author" content={article.author} />
        <meta property="article:published_time" content={article.date} />
        {article.tags?.map((tag) => (
          <meta property="article:tag" content={tag} key={tag} />
        ))}
      </Helmet>
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex flex-col lg:flex-row lg:gap-8">
          <article className="flex-1 min-w-0">
            <div className="flex flex-wrap items-center text-sm sm:text-base text-gray-600 mb-6 sm:mb-8">
              <span>{article.author}</span>
              <span className="mx-2">•</span>
              <span>{article.date}</span>
              {article.readTime && (
                <>
                  <span className="mx-2">•</span>
                  <span>{article.readTime} min leestijd</span>
                </>
              )}
            </div>

            <div 
              className="prose prose-base sm:prose-lg max-w-none prose-headings:text-gray-900 prose-p:text-gray-700 prose-li:text-gray-700"
              dangerouslySetInnerHTML={{ __html: article.content }} 
            />
          </article>

          <aside className="w-full lg:w-80 mt-8 lg:mt-0">
            <div className="sticky top-4">
              <h2 className="text-xl font-semibold mb-4">Aanbevolen Klinieken</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 gap-4">
                {randomClinics.map((clinic) => (
                  <Link 
                    key={clinic.id}
                    to={`/bedrijf/${clinic.slug}`}
                    className="block hover:shadow-lg transition-shadow"
                  >
                    <div className="p-4 border rounded-lg">
                      <div 
                        className="h-40 mb-4 rounded-md bg-cover bg-center"
                        style={{ backgroundImage: `url(${clinic.images.card})` }}
                      />
                      <h3 className="font-semibold">{clinic.name}</h3>
                      <p className="text-sm text-gray-600 mt-1">{clinic.city}</p>
                      <p className="text-sm text-gray-700 mt-2 line-clamp-2">{clinic.description}</p>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </aside>
        </div>
      </div>
    </>
  );
}; 