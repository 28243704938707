import { Clinic } from '../types/Clinic';

export const zantmanKliniek: Clinic = {
  id: 'zantman-kliniek',
  slug: 'zantman-kliniek',
  name: 'Zantman Kliniek',
  description: 'Met meer dan 45 jaar ervaring is Zantman Kliniek een deskundige en betrouwbare kliniek voor haartransplantaties. We bieden zorgvuldige en persoonlijke behandelingen met natuurlijke resultaten.',
  address: 'Bentincklaan 21A, 3039 KG Rotterdam, Nederland',
  city: 'Rotterdam',
  phone: '+31 (0) 10 276 2599',
  email: 'info@zantmankliniek.nl',
  rating: 4.8,
  reviewCount: 150,
  isClaimed: false,
  imageUrl: '/clinics/zantman-kliniek/clinic.jpg',
  images: {
    banner: '/clinics/zantman-kliniek/banner.jpg',
    card: '/clinics/zantman-kliniek/baardtransplantatie-3.jpg',
    gallery: {
      clinic: '/clinics/zantman-kliniek/clinic.jpg',
      team: '/clinics/zantman-kliniek/team.jpg'
    }
  },
  openingHours: {
    days: 'Maandag t/m Vrijdag',
    hours: '09:00 - 17:00'
  },
  benefits: [
    {
      title: 'Meer dan 45 jaar ervaring',
      description: 'Jarenlange expertise in haar- en baardtransplantaties'
    },
    {
      title: 'Deskundig en betrouwbaar team',
      description: 'Ervaren specialisten met focus op kwaliteit'
    },
    {
      title: 'Zorgvuldige en persoonlijke aanpak',
      description: 'Behandeling op maat voor uw specifieke situatie'
    },
    {
      title: 'Natuurlijke resultaten',
      description: 'Gegarandeerd natuurlijk ogende resultaten'
    },
    {
      title: 'Modernste FUE-methode',
      description: 'Gebruik van de nieuwste haartransplantatie technieken'
    },
    {
      title: 'Haarsparende technieken',
      description: 'Minimaal invasieve behandelmethoden'
    }
  ],
  aboutContent: {
    title: 'Specialist in Haar- en Baardtransplantaties',
    content: `zantman Kliniek is gespecialiseerd in haartransplantaties en baardtransplantaties. Met meer dan 45 jaar ervaring bieden wij deskundige en betrouwbare behandelingen met natuurlijke resultaten.

Onze kliniek staat bekend om haar zorgvuldige en persoonlijke aanpak. We gebruiken de modernste FUE-methode (Follicular Unit Extraction) voor zowel haar- als baardtransplantaties, waarbij we haarsparend te werk gaan.

Bij zantman Kliniek krijgt u een behandeling die volledig is afgestemd op uw wensen en situatie. Ons ervaren medisch team zorgt voor optimale resultaten die er natuurlijk uitzien.`,
    highlights: [
      'Meer dan 45 jaar ervaring',
      'Deskundig en betrouwbaar',
      'Zorgvuldig en persoonlijk',
      'Natuurlijke resultaten',
      'Modernste FUE-methode',
      'Haarsparende technieken'
    ]
  },
  expertise: [
    'Haartransplantatie',
    'Baardtransplantatie',
    'Wenkbrauwtransplantatie',
    'Krullend en kroeshaar specialisatie'
  ],
  treatments: [
    'FUE Haartransplantatie',
    'Baardtransplantatie',
    'Wenkbrauwtransplantatie',
    'Haarsparende behandelingen'
  ]
}; 