import { Article } from '../types/Article';
import { articles } from '../articles';

export const getAllArticles = (): Article[] => {
  return Object.values(articles).sort((a, b) => 
    new Date(b.date).getTime() - new Date(a.date).getTime()
  );
};

export const getArticleBySlug = (slug: string): Article | null => {
  return articles[slug] || null;
};

export const getRelatedArticles = (currentSlug: string, limit = 3): Article[] => {
  return Object.values(articles)
    .filter(article => article.slug !== currentSlug)
    .sort(() => Math.random() - 0.5)
    .slice(0, limit);
};