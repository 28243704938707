import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Home } from './pages/Home';
import { CompanyDetails } from './pages/CompanyDetails';
import { CompaniesDirectory } from './pages/CompaniesDirectory';
import { CityCompanies } from './pages/CityCompanies';
import { AddClinic } from './pages/AddClinic';
import { Blog } from './pages/Blog';
import { ArticlePage } from './pages/ArticlePage';
import { Privacy } from './pages/Privacy';
import { Voorwaarden } from './pages/Voorwaarden';

const router = {
  future: {
    v7_startTransition: true
  }
};

function App() {
  return (
    <Router {...router}>
      <div className="min-h-screen flex flex-col bg-gray-50">
        <Header />
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/bedrijven" element={<CompaniesDirectory />} />
            <Route path="/bedrijven/:city" element={<CityCompanies />} />
            <Route path="/bedrijf/:id" element={<CompanyDetails />} />
            <Route path="/kliniek-toevoegen" element={<AddClinic />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:slug" element={<ArticlePage />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/voorwaarden" element={<Voorwaarden />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;