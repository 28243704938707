import { useParams } from 'react-router-dom';
import { getClinicBySlug } from '../utils/clinics';
import { CompanyInfo } from '../components/company/CompanyInfo';
import { UnclaimedNotice } from '../components/company/UnclaimedNotice';
import { SEO } from '../components/SEO';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { MapPin, Phone, Mail, Clock } from 'lucide-react';
import { ImageWithFallback } from '../components/ImageWithFallback';

export const CompanyDetails = () => {
  const { id } = useParams<{ id: string }>();
  const clinic = getClinicBySlug(id || '');

  if (!clinic) {
    return <div>Kliniek niet gevonden</div>;
  }

  const mapsUrl = `https://www.google.com/maps/search/${encodeURIComponent(`${clinic.name} ${clinic.address} ${clinic.city}`)}`;

  return (
    <>
      <SEO 
        title={`${clinic.name} | Baardtransplantatie in ${clinic.city} | Baardtransplantatie.nl`}
        description={clinic.description}
        canonical={`/bedrijf/${clinic.slug}`}
      />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <Breadcrumbs clinic={clinic} />
        
        <div className="mb-8">
          <h1 className="text-4xl font-bold text-gray-900 mb-2">{clinic.name}</h1>
          <a 
            href={mapsUrl}
            target="_blank"
            rel="nofollow noopener noreferrer"
            className="flex items-center text-gray-400 text-[13px] hover:text-gray-600 transition-colors group"
          >
            <MapPin className="w-4 h-4 text-blue-600 shrink-0 group-hover:text-blue-700" />
            <div className="ml-1.5">
              <span className="text-gray-600 group-hover:text-gray-700">{clinic.address}</span>
              <span className="mx-1 text-gray-400">•</span>
              <span className="font-medium text-blue-600 group-hover:text-blue-700">{clinic.city}</span>
            </div>
          </a>
        </div>

        <div className="flex flex-col lg:flex-row gap-8">
          {/* Left column - Company Info */}
          <div className="flex-1">
            {clinic.images?.banner && (
              <div className="mb-8 rounded-lg overflow-hidden h-[400px]">
                <ImageWithFallback
                  src={clinic.images.banner}
                  alt={clinic.name}
                  className="w-full h-full object-cover"
                />
              </div>
            )}
            
            {/* Show unclaimed notice if isClaimed is false or undefined */}
            {(clinic.isClaimed === false || clinic.isClaimed === undefined) && (
              <UnclaimedNotice clinicName={clinic.name} />
            )}

            <CompanyInfo company={clinic} />
          </div>

          {/* Right column - Contact Info */}
          <div className="w-full lg:w-80 shrink-0">
            <div className="bg-white rounded-lg shadow-lg p-6 sticky top-4">
              <h2 className="text-xl font-semibold mb-6">Contact Informatie</h2>
              
              <div className="space-y-4 mb-6">
                <div className="flex items-start text-gray-700">
                  <MapPin className="w-5 h-5 mt-1 mr-3 text-blue-600 shrink-0" />
                  <div>
                    <p>{clinic.address}</p>
                    <p>{clinic.city}</p>
                  </div>
                </div>

                <div className="flex items-center text-gray-700">
                  <Phone className="w-5 h-5 mr-3 text-blue-600 shrink-0" />
                  <span>{clinic.phone}</span>
                </div>

                <div className="flex items-center text-gray-700">
                  <Mail className="w-5 h-5 mr-3 text-blue-600 shrink-0" />
                  <span>{clinic.email}</span>
                </div>

                <div className="flex items-center text-gray-700">
                  <Clock className="w-5 h-5 mr-3 text-blue-600 shrink-0" />
                  <div>
                    <p>{clinic.openingHours.days}</p>
                    <p>{clinic.openingHours.hours}</p>
                  </div>
                </div>
              </div>

              <div className="space-y-3">
                <a
                  href={`mailto:${clinic.email}`}
                  className="block w-full bg-blue-600 text-white text-center py-3 px-4 rounded-md hover:bg-blue-700 transition-colors"
                >
                  <Mail className="w-4 h-4 inline-block mr-2" />
                  E-mail versturen
                </a>

                <a
                  href={`tel:${clinic.phone}`}
                  className="block w-full bg-gray-100 text-gray-700 text-center py-3 px-4 rounded-md hover:bg-gray-200 transition-colors"
                >
                  <Phone className="w-4 h-4 inline-block mr-2" />
                  Bellen
                </a>
              </div>

              <p className="text-sm text-gray-500 text-center mt-4">
                Meestal binnen 24 uur reactie
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};