import { useState, useEffect } from 'react';
import type { DetailedHTMLProps, ImgHTMLAttributes } from 'react';

interface ImageWithFallbackProps extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  src?: string;
  alt: string;
}

// Cache for image extension lookups
const imageCache = new Map<string, string | null>();

const findImage = async (basePath: string): Promise<string | null> => {
  console.log('Finding image for:', basePath);
  
  const cachedResult = imageCache.get(basePath);
  if (cachedResult !== undefined) {
    console.log('Found in cache:', cachedResult);
    return cachedResult;
  }

  const extensions = ['avif', 'webp', 'jpg', 'jpeg', 'png', 'gif'];
  
  for (const ext of extensions) {
    try {
      const url = `${basePath}.${ext}`;
      console.log('Trying:', url);
      
      const img = new Image();
      const loadPromise = new Promise<boolean>((resolve) => {
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
      });
      
      img.src = url;
      const success = await loadPromise;
      
      if (success) {
        console.log('Found image:', url);
        imageCache.set(basePath, url);
        return url;
      }
    } catch (error) {
      console.debug(`Failed to load ${ext} for ${basePath}:`, error);
      continue;
    }
  }
  
  console.log('No image found for:', basePath);
  imageCache.set(basePath, null);
  return null;
};

export const ImageWithFallback = ({ src, alt, className, ...props }: ImageWithFallbackProps) => {
  const [error, setError] = useState(false);
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  useEffect(() => {
    console.log('ImageWithFallback: Loading banner image:', {
      src,
      hasExtension: src?.match(/\.(avif|webp|jpg|jpeg|png|gif)$/i) ? true : false
    });
    
    if (!src) {
      console.log('No source provided');
      return;
    }

    // If the path already includes an extension, use it directly
    if (src.match(/\.(avif|webp|jpg|jpeg|png|gif)$/i)) {
      console.log('Using direct image path:', src);
      const img = new Image();
      img.onload = () => {
        setImageSrc(src);
        setError(false);
      };
      img.onerror = () => setError(true);
      img.src = src;
    } else {
      // Try to find the correct extension
      console.log('Searching for image with extensions:', src);
      findImage(src).then(foundSrc => {
        if (foundSrc) {
          setImageSrc(foundSrc);
          setError(false);
        } else {
          setError(true);
        }
      });
    }
  }, [src]);

  if (error || !imageSrc) {
    console.log('Showing fallback for:', src);
    return (
      <div className={`bg-gray-200 flex items-center justify-center ${className}`}>
        <span className="text-gray-400 text-sm">Geen afbeelding beschikbaar</span>
      </div>
    );
  }

  return (
    <img
      {...props}
      src={imageSrc}
      alt={alt}
      className={className}
      loading="lazy"
      decoding="async"
      onError={() => setError(true)}
    />
  );
};