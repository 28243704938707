import { Clinic } from '../types/Clinic';
import { clinic as nielsenHaarkliniek } from '../clinics/nielsen-haarkliniek';
import { zantmanKliniek } from '../clinics/zantman-kliniek';
import { beauclinic } from '../clinics/beauclinic';

export const clinics: Clinic[] = [
  nielsenHaarkliniek,
  zantmanKliniek,
  beauclinic
];

// Pre-process searchable text for each clinic
const searchIndex = clinics.map(clinic => ({
  ...clinic,
  searchText: `${clinic.name} ${clinic.city} ${clinic.description}`.toLowerCase()
}));

export const getAllClinics = (): Clinic[] => {
  return [...clinics].sort((a, b) => {
    const ratingA = a.rating ?? 0;
    const ratingB = b.rating ?? 0;
    return ratingB - ratingA;
  });
};

export const getClinicBySlug = (slug: string): Clinic | undefined => {
  return clinics.find(clinic => clinic.slug === slug);
};

export const getClinicsByCity = (city: string): Clinic[] => {
  return clinics.filter(clinic => 
    clinic.city.toLowerCase() === city.toLowerCase()
  );
};

export const searchClinics = (query: string): Clinic[] => {
  if (!query) return clinics;
  
  const searchTerm = query.toLowerCase();
  
  // Quick exact match for city first
  if (searchTerm.length >= 2) {
    const cityMatches = searchIndex.filter(clinic => 
      clinic.city.toLowerCase().startsWith(searchTerm)
    );
    if (cityMatches.length > 0) return cityMatches;
  }

  // Fall back to general search
  return searchIndex.filter(clinic => 
    clinic.searchText.includes(searchTerm)
  );
};