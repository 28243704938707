import { useState, useCallback } from 'react';
import { SearchBar } from '../components/SearchBar';
import { CompanyCard } from '../components/CompanyCard';
import { getAllClinics, searchClinics } from '../utils/clinics';
import { SEO } from '../components/SEO';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const CLINICS_PER_PAGE = 39;

export const Home = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  
  const handleSearch = useCallback((query: string) => {
    setSearchQuery(query.trim());
    setCurrentPage(1); // Reset to first page on new search
  }, []);

  const allClinics = searchQuery ? searchClinics(searchQuery) : getAllClinics();
  const totalPages = Math.ceil(allClinics.length / CLINICS_PER_PAGE);
  
  // Get clinics for current page
  const clinics = allClinics.slice(
    (currentPage - 1) * CLINICS_PER_PAGE,
    currentPage * CLINICS_PER_PAGE
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <SEO 
        title="Baardtransplantatie.nl - Vind de Beste Baardtransplantatie Klinieken"
        description="Vergelijk en contacteer top baardtransplantatie specialisten in Nederland. Vind de beste kliniek voor uw baardtransplantatie."
        canonical="/"
      />

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Vind de Beste Baardtransplantatie Klinieken
          </h1>
          <p className="text-xl text-gray-600">
            Vergelijk en contacteer top baardtransplantatie specialisten in Nederland
          </p>
        </div>

        <SearchBar onSearch={handleSearch} />

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-8">
          {clinics.map((clinic) => (
            <CompanyCard
              key={clinic.id}
              id={clinic.id}
              name={clinic.name}
              description={clinic.description}
              address={clinic.address}
              city={clinic.city}
              images={clinic.images}
            />
          ))}
        </div>

        {totalPages > 1 && (
          <div className="flex justify-center items-center space-x-4">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="p-2 rounded-full hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <ChevronLeft className="w-6 h-6" />
            </button>
            
            <div className="flex items-center space-x-2">
              {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                <button
                  key={page}
                  onClick={() => handlePageChange(page)}
                  className={`px-4 py-2 rounded-md ${
                    currentPage === page
                      ? 'bg-blue-600 text-white'
                      : 'hover:bg-gray-100'
                  }`}
                >
                  {page}
                </button>
              ))}
            </div>

            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="p-2 rounded-full hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <ChevronRight className="w-6 h-6" />
            </button>
          </div>
        )}
      </main>
    </>
  );
};