import { Clinic } from '../types/Clinic';

export const clinic: Clinic = {
  id: 'nielsen-haarkliniek',
  slug: 'nielsen-haarkliniek',
  name: 'Nielsen Haar Kliniek',
  description: 'Gespecialiseerde kliniek voor haar- en baardtransplantaties met geavanceerde technieken en persoonlijke benadering.',
  address: 'Hoofdweg 848',
  city: 'Hoofddorp',
  rating: 4.8,
  reviewCount: 175,
  isClaimed: false,
  imageUrl: 'https://',
  images: {
    card: '/clinics/nielsen-haarkliniek/baardtransplantatie-2.jpg',
    banner: 'https://',
    gallery: {
      clinic: 'https://',
      team: 'https://'
    }
  },
  phone: '+31 (0)23 555 1234',
  email: 'info@nielsenhaarkliniek.nl',
  openingHours: {
    days: 'Ma - Vr',
    hours: '09:00 - 17:30'
  },
  expertise: [
    'Baardtransplantatie',
    'Haartransplantatie',
    'FUE-techniek',
    'Persoonlijke benadering'
  ],
  benefits: [
    {
      title: 'Ervaren Specialisten',
      description: 'Team van ervaren artsen met specialisatie in haar- en baardtransplantaties'
    },
    {
      title: 'Moderne Technieken',
      description: 'Gebruik van de nieuwste FUE-technieken voor optimale resultaten'
    }
  ],
  treatments: [
    'Baardtransplantatie',
    'Haartransplantatie',
    'Wenkbrauwtransplantatie'
  ],
  aboutContent: {
    title: 'Expertise in Haar- en Baardtransplantaties',
    content: 'Nielsen Haar Kliniek is een gespecialiseerde kliniek voor haar- en baardtransplantaties. Met onze jarenlange ervaring en moderne technieken streven wij naar de beste resultaten voor onze patiënten.',
    highlights: [
      'Meer dan 10 jaar ervaring',
      'Persoonlijke behandelplannen',
      'Moderne FUE-technieken',
      'Uitgebreide nazorg'
    ]
  }
}; 