import { useParams } from 'react-router-dom';
import { getArticleBySlug } from '../utils/blog';
import { SEO } from '../components/SEO';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { BlogPost } from '../components/BlogPost';
import { NotFound } from './NotFound';
import { useEffect, useState } from 'react';

export const ArticlePage = () => {
  const { slug } = useParams<{ slug: string }>();
  const [article, setArticle] = useState<ReturnType<typeof getArticleBySlug>>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadArticle = async () => {
      setIsLoading(true);
      try {
        const foundArticle = slug ? getArticleBySlug(slug) : null;
        setArticle(foundArticle);
      } finally {
        setIsLoading(false);
      }
    };

    loadArticle();
  }, [slug]);

  if (isLoading) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="animate-pulse">
          <div className="h-8 bg-gray-200 rounded w-3/4 mb-4" />
          <div className="h-4 bg-gray-200 rounded w-1/4 mb-8" />
          <div className="space-y-4">
            <div className="h-4 bg-gray-200 rounded w-full" />
            <div className="h-4 bg-gray-200 rounded w-full" />
            <div className="h-4 bg-gray-200 rounded w-3/4" />
          </div>
        </div>
      </div>
    );
  }

  if (!article) {
    return <NotFound />;
  }

  return (
    <>
      <SEO 
        title={`${article.title} | Baardtransplantatie.nl`}
        description={article.excerpt}
        canonical={`/blog/${article.slug}`}
        ogImage={article.images.banner}
        ogType="article"
      />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <Breadcrumbs />
        <BlogPost article={article} />
      </div>
    </>
  );
};