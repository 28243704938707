import { getAllArticles } from '../utils/blog';
import { SEO } from '../components/SEO';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { BlogCard } from '../components/BlogCard';

export const Blog = () => {
  const articles = getAllArticles();

  return (
    <>
      <SEO 
        title="Blog | Alles over Baardtransplantaties | Baardtransplantatie.nl"
        description="Lees meer over baardtransplantaties, technieken, herstelperiode en ervaringen. Expert artikelen en tips voor een succesvolle behandeling."
        canonical="/blog"
      />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12">
        <Breadcrumbs />
        
        <header className="text-center mb-8 sm:mb-12">
          <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-gray-900 mb-2 sm:mb-4">
            Blog over Baardtransplantaties
          </h1>
          <p className="text-base sm:text-lg lg:text-xl text-gray-600 px-2">
            Expert artikelen en tips over baardtransplantaties
          </p>
        </header>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
          {articles.map((article) => (
            <BlogCard key={article.slug} article={article} />
          ))}
        </div>
      </div>
    </>
  );
};