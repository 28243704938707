import { useParams } from 'react-router-dom';
import { getAllClinics } from '../utils/clinics';
import { SEO } from '../components/SEO';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { CompanyCard } from '../components/CompanyCard';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useState } from 'react';

const CLINICS_PER_PAGE = 9;

export const CityCompanies = () => {
  const { city } = useParams<{ city: string }>();
  const [currentPage, setCurrentPage] = useState(1);

  const allClinics = getAllClinics().filter(
    clinic => clinic.city.toLowerCase() === city?.toLowerCase()
  );

  const totalPages = Math.ceil(allClinics.length / CLINICS_PER_PAGE);
  
  // Get clinics for current page
  const clinics = allClinics.slice(
    (currentPage - 1) * CLINICS_PER_PAGE,
    currentPage * CLINICS_PER_PAGE
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const cityName = city ? city.charAt(0).toUpperCase() + city.slice(1) : '';

  return (
    <>
      <SEO 
        title={`Baardtransplantatie Klinieken in ${cityName} | Baardtransplantatie.nl`}
        description={`Ontdek baardtransplantatie klinieken in ${cityName}. Vergelijk specialisten en vind de beste kliniek voor uw behandeling.`}
        canonical={`/bedrijven/${city?.toLowerCase()}`}
      />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <Breadcrumbs />
        
        <h1 className="text-3xl font-bold text-gray-900 mb-8">
          Baardtransplantatie Klinieken in {cityName}
        </h1>

        {clinics.length > 0 ? (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-8">
              {clinics.map((clinic) => (
                <CompanyCard
                  key={clinic.id}
                  id={clinic.id}
                  name={clinic.name}
                  description={clinic.description}
                  address={clinic.address}
                  city={clinic.city}
                  images={clinic.images}
                />
              ))}
            </div>

            {totalPages > 1 && (
              <div className="flex justify-center items-center space-x-4">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="p-2 rounded-full hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <ChevronLeft className="w-6 h-6" />
                </button>
                
                <div className="flex items-center space-x-2">
                  {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                    <button
                      key={page}
                      onClick={() => handlePageChange(page)}
                      className={`px-4 py-2 rounded-md ${
                        currentPage === page
                          ? 'bg-blue-600 text-white'
                          : 'hover:bg-gray-100'
                      }`}
                    >
                      {page}
                    </button>
                  ))}
                </div>

                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="p-2 rounded-full hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <ChevronRight className="w-6 h-6" />
                </button>
              </div>
            )}
          </>
        ) : (
          <div className="text-center py-12">
            <p className="text-gray-600">
              Geen klinieken gevonden in {cityName}
            </p>
          </div>
        )}
      </div>
    </>
  );
};