import { Link } from 'react-router-dom';
import { getAllClinics } from '../utils/clinics';
import { SEO } from '../components/SEO';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useState } from 'react';

const CLINICS_PER_PAGE = 9;

export const CompaniesDirectory = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const allClinics = getAllClinics();
  const totalPages = Math.ceil(allClinics.length / CLINICS_PER_PAGE);

  // Get clinics for current page
  const clinics = allClinics.slice(
    (currentPage - 1) * CLINICS_PER_PAGE,
    currentPage * CLINICS_PER_PAGE
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const cities = [...new Set(clinics.map(clinic => clinic.city))].sort();

  return (
    <>
      <SEO 
        title="Baardtransplantatie Klinieken per Stad | Baardtransplantatie.nl"
        description="Vind baardtransplantatie klinieken in uw stad. Bekijk en vergelijk specialisten in heel Nederland."
        canonical="/bedrijven"
      />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <Breadcrumbs />
        
        <h1 className="text-3xl font-bold text-gray-900 mb-8">
          Baardtransplantatie Klinieken per Stad
        </h1>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-8">
          {cities.map(city => {
            const cityClinics = clinics.filter(clinic => clinic.city === city);
            return (
              <div key={city} className="bg-white rounded-lg shadow-md p-6">
                <h2 className="text-xl font-semibold mb-4">
                  <Link 
                    to={`/bedrijven/${city.toLowerCase()}`}
                    className="text-blue-600 hover:text-blue-800"
                  >
                    {city}
                  </Link>
                </h2>
                <p className="text-gray-600 mb-4">
                  {cityClinics.length} kliniek{cityClinics.length !== 1 ? 'en' : ''}
                </p>
                <ul className="space-y-2">
                  {cityClinics.map(clinic => (
                    <li key={clinic.id}>
                      <Link 
                        to={`/bedrijf/${clinic.slug}`}
                        className="text-gray-700 hover:text-blue-600"
                      >
                        {clinic.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>

        {totalPages > 1 && (
          <div className="flex justify-center items-center space-x-4">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="p-2 rounded-full hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <ChevronLeft className="w-6 h-6" />
            </button>
            
            <div className="flex items-center space-x-2">
              {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                <button
                  key={page}
                  onClick={() => handlePageChange(page)}
                  className={`px-4 py-2 rounded-md ${
                    currentPage === page
                      ? 'bg-blue-600 text-white'
                      : 'hover:bg-gray-100'
                  }`}
                >
                  {page}
                </button>
              ))}
            </div>

            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="p-2 rounded-full hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <ChevronRight className="w-6 h-6" />
            </button>
          </div>
        )}
      </div>
    </>
  );
};