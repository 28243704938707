import { Clinic } from '../types/Clinic';

export const beauclinic: Clinic = {
  id: 'beauclinic',
  slug: 'beauclinic',
  name: 'Beauclinic',
  description: 'Beauclinic is een toonaangevende kliniek voor haartransplantaties met een 9,0 beoordeling op basis van meer dan 2.000 reviews. We bieden levenslange zekerheid en garanderen veiligheid en kwaliteit in al onze behandelingen.',
  address: 'Wiersedreef 30, 3433 ZX Nieuwegein',
  city: 'Nieuwegein',
  phone: '(085) 303 1570',
  email: 'info@beauclinic.nl',
  rating: 9.0,
  reviewCount: 2000,
  isClaimed: true,
  imageUrl: 'https://',
  images: {
    banner: 'https://',
    card: '/clinics/beauclinic/baardtransplantatie-1.jpg',
      gallery: {
      clinic: 'https://',
      team: 'https://'
    }
  },
  openingHours: {
    days: 'Maandag t/m Zaterdag',
    hours: '09:00 - 17:00'
  },
  benefits: [
    {
      title: '9,0 op basis van 2.000+ reviews',
      description: 'Hooggewaardeerde kliniek met duizenden tevreden klanten'
    },
    {
      title: 'Levenslange zekerheid',
      description: 'Garantie op blijvende resultaten van uw behandeling'
    },
    {
      title: 'Veiligheid en kwaliteit gegarandeerd',
      description: 'Gecertificeerde specialisten en moderne behandeltechnieken'
    },
    {
      title: 'Aanbevolen door BN\'ers',
      description: 'Vertrouwd door bekende Nederlanders voor hun behandelingen'
    },
    {
      title: 'NC Technology',
      description: 'Geavanceerde technologie voor optimale resultaten'
    },
    {
      title: 'Persoonlijke aanpak',
      description: 'Behandelplan op maat voor uw specifieke situatie'
    }
  ],
  aboutContent: {
    title: 'Over Beauclinic',
    content: `Beauclinic is een toonaangevende kliniek gespecialiseerd in haartransplantaties voor zowel mannen als vrouwen. Met onze innovatieve NC Technology en ervaren specialisten bieden wij hoogwaardige behandelingen met gegarandeerde resultaten.

Wij onderscheiden ons door onze persoonlijke aanpak en levenslange zekerheid op onze behandelingen. Onze kliniek heeft een uitstekende reputatie met een 9,0 beoordeling op basis van meer dan 2.000 reviews en wordt aanbevolen door vele bekende Nederlanders.

Bij Beauclinic beginnen we altijd met een gratis adviesgesprek om uw wensen te bespreken en een persoonlijk behandelplan op te stellen. We bieden verschillende behandelingen aan, waaronder haartransplantaties zonder scheren, wenkbrauwtransplantaties en baardtransplantaties.`,
    highlights: [
      'Innovatieve NC Technology',
      'Gratis adviesgesprek',
      'Persoonlijke behandelplannen',
      'Levenslange zekerheid',
      'Uitgebreide nazorg',
      'Gecertificeerde specialisten'
    ]
  },
  expertise: [
    'Haartransplantatie mannen',
    'Haartransplantatie vrouwen',
    'Haartransplantatie zonder scheren',
    'Wenkbrauwtransplantatie',
    'Baardtransplantatie',
    'Haarverbetering behandeling'
  ],
  treatments: [
    'FUE Haartransplantatie',
    'DHI Haartransplantatie',
    'Haartransplantatie zonder scheren',
    'Wenkbrauwtransplantatie',
    'Baardtransplantatie',
    'Receptor-haarverbetering'
  ]
}; 