import { Link } from 'react-router-dom';
import { SEO } from '../components/SEO';

export const NotFound = () => {
  return (
    <>
      <SEO 
        title="Pagina Niet Gevonden | Baardtransplantatie.nl"
        description="De pagina die u zoekt bestaat niet of is verplaatst."
        canonical="/404"
      />

      <div className="min-h-screen bg-gray-50 flex items-center justify-center px-4">
        <div className="text-center">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            404 - Pagina Niet Gevonden
          </h1>
          <p className="text-gray-600 mb-8">
            De pagina die u zoekt bestaat niet of is verplaatst.
          </p>
          <Link 
            to="/"
            className="inline-block bg-blue-600 text-white px-6 py-3 rounded-md hover:bg-blue-700 transition-colors"
          >
            Terug naar home
          </Link>
        </div>
      </div>
    </>
  );
}; 