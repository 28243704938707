import { Link } from 'react-router-dom';
import { Info } from 'lucide-react';

interface UnclaimedNoticeProps {
  clinicName: string;
}

export const UnclaimedNotice = ({ clinicName }: UnclaimedNoticeProps) => {
  return (
    <div className="bg-blue-50 border border-blue-100 rounded-lg p-4 mb-8">
      <div className="flex items-start">
        <Info className="w-5 h-5 text-blue-600 shrink-0 mt-0.5" />
        <div className="ml-3">
          <h3 className="text-lg font-semibold text-blue-900 mb-1">
            Bent u de eigenaar van {clinicName}?
          </h3>
          <p className="text-blue-800">
            Wij helpen u graag deze pagina te personaliseren. We kunnen:
          </p>
          <ul className="mt-2 space-y-1 text-blue-700">
            <li>• Uw bedrijfsinformatie bijwerken</li>
            <li>• Foto's van uw kliniek toevoegen</li>
            <li>• Reviews van externe sites integreren</li>
            <li>• Uw behandelingen en expertise uitlichten</li>
          </ul>
          <div className="mt-4">
            <Link
              to="/kliniek-toevoegen"
              className="inline-flex items-center text-blue-600 hover:text-blue-800 font-medium"
            >
              Neem contact op
              <svg className="w-4 h-4 ml-1" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}; 