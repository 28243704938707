import { CheckCircle, Award, Clock } from 'lucide-react';
import { ImageWithFallback } from '../ImageWithFallback';

interface ReviewSite {
  name: string;
  url: string;
}

interface CompanyInfoProps {
  company: {
    name: string;
    description: string;
    reviewSites?: ReviewSite[];
    images?: {
      gallery?: {
        clinic?: string;
        team?: string;
        treatment?: string;
      };
    };
    aboutContent: {
      title: string;
      content: string;
      highlights?: string[];
    };
  };
}

export const CompanyInfo = ({ company }: CompanyInfoProps) => {
  const hasGallery = company.images?.gallery && (
    company.images.gallery.clinic || 
    company.images.gallery.team || 
    company.images.gallery.treatment
  );

  return (
    <>
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Over {company.name}</h2>
        <p className="text-gray-700 leading-relaxed mb-6">{company.description}</p>
        
        <div className="prose max-w-none text-gray-700">
          <h3 className="text-xl font-semibold mb-4">{company.aboutContent.title}</h3>
          <div className="space-y-4">
            {company.aboutContent.content.split('\n\n').map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
          </div>
          
          {company.aboutContent.highlights && (
            <div className="mt-6">
              <ul className="list-disc pl-5 space-y-2">
                {company.aboutContent.highlights.map((highlight, index) => (
                  <li key={index}>{highlight}</li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <h3 className="text-xl font-semibold mb-4 mt-8">Onze Expertise</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {[
            { icon: CheckCircle, text: 'FUE Techniek' },
            { icon: CheckCircle, text: 'DHI Methode' },
            { icon: CheckCircle, text: 'Persoonlijk behandelplan' },
            { icon: CheckCircle, text: 'Natuurlijke resultaten' }
          ].map((item, index) => (
            <div key={index} className="flex items-center space-x-2">
              <item.icon className="w-5 h-5 text-green-500" />
              <span>{item.text}</span>
            </div>
          ))}
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Waarom Kiezen voor {company.name}</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {[
            {
              icon: Award,
              title: 'Gecertificeerde Specialisten',
              description: 'Ons team bestaat uit ervaren en gecertificeerde artsen'
            },
            {
              icon: Clock,
              title: 'Snelle Herstelperiode',
              description: 'Minimale downtime en optimale nazorg'
            }
          ].map((item, index) => (
            <div key={index} className="flex space-x-4">
              <div className="flex-shrink-0">
                <item.icon className="w-8 h-8 text-blue-600" />
              </div>
              <div>
                <h3 className="font-semibold mb-2">{item.title}</h3>
                <p className="text-gray-600">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      {hasGallery && (
        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-6">Onze Kliniek</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {company.images?.gallery?.clinic && (
              <div className="h-64 rounded-lg overflow-hidden">
                <ImageWithFallback
                  src={company.images.gallery.clinic}
                  alt="Onze kliniek"
                  className="w-full h-full object-cover"
                />
              </div>
            )}
            {company.images?.gallery?.team && (
              <div className="h-64 rounded-lg overflow-hidden">
                <ImageWithFallback
                  src={company.images.gallery.team}
                  alt="Ons team"
                  className="w-full h-full object-cover"
                />
              </div>
            )}
            {company.images?.gallery?.treatment && (
              <div className="h-64 rounded-lg overflow-hidden">
                <ImageWithFallback
                  src={company.images.gallery.treatment}
                  alt="Behandeling"
                  className="w-full h-full object-cover"
                />
              </div>
            )}
          </div>
        </section>
      )}

      {company.reviewSites && company.reviewSites.length > 0 && (
        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Reviews</h2>
          <p className="text-gray-700 mb-4">
            U kunt reviews over {company.name} vinden op de volgende websites:
          </p>
          <ul className="space-y-2">
            {company.reviewSites.map((site, index) => (
              <li key={index}>
                <a 
                  href={site.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:text-blue-800 flex items-center"
                >
                  <span className="mr-2">{site.name}</span>
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                  </svg>
                </a>
              </li>
            ))}
          </ul>
        </section>
      )}
    </>
  );
};