import { Link, useLocation } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';

export const Breadcrumbs = ({ clinic }: { clinic?: { name: string; city: string } }) => {
  const location = useLocation();

  const getBreadcrumbs = () => {
    if (clinic) {
      return [
        { name: 'Home', path: '/' },
        { name: 'Bedrijven', path: '/bedrijven' },
        { name: clinic.city, path: `/bedrijven/${clinic.city.toLowerCase()}` },
        { name: clinic.name, path: null }
      ];
    }

    if (location.pathname.startsWith('/blog')) {
      return [
        { name: 'Home', path: '/' },
        { name: 'Blog', path: '/blog' }
      ];
    }

    if (location.pathname === '/bedrijven') {
      return [
        { name: 'Home', path: '/' },
        { name: 'Bedrijven', path: null }
      ];
    }

    return [{ name: 'Home', path: '/' }];
  };

  const breadcrumbs = getBreadcrumbs();

  return (
    <nav aria-label="Breadcrumb" className="mb-6">
      <ol className="flex items-center space-x-2">
        {breadcrumbs.map((crumb, index) => (
          <li key={index} className="flex items-center">
            {index > 0 && (
              <ChevronRight className="w-4 h-4 text-gray-400 mx-2" />
            )}
            {crumb.path ? (
              <Link
                to={crumb.path}
                className="text-blue-600 hover:text-blue-800"
              >
                {crumb.name}
              </Link>
            ) : (
              <span className="text-gray-500">{crumb.name}</span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};