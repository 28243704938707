import { Link } from 'react-router-dom';
import { ImageWithFallback } from './ImageWithFallback';
import { ClinicImages } from '../types/Clinic';
import { MapPin } from 'lucide-react';

interface CompanyCardProps {
  id: string;
  name: string;
  description: string;
  address: string;
  city: string;
  images?: ClinicImages;
}

export const CompanyCard = ({
  id,
  name,
  description,
  address,
  city,
  images
}: CompanyCardProps) => {
  const mapsUrl = `https://www.google.com/maps/search/${encodeURIComponent(`${name} ${address} ${city}`)}`;

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden h-full flex flex-col">
      <div className="h-48 bg-gray-200">
        <ImageWithFallback
          src={images?.card}
          alt={name}
          className="w-full h-full object-cover"
        />
      </div>
      <div className="p-6 flex flex-col flex-grow">
        <div className="flex-grow">
          <h3 className="text-xl font-semibold mb-2">{name}</h3>
          <p className="text-gray-600 mb-4">{description}</p>
        </div>
        <div>
          <Link 
            to={`/bedrijf/${id}`}
            className="block w-full bg-blue-600 text-white text-center py-2 px-4 rounded-md hover:bg-blue-700 transition-colors mb-4"
          >
            Meer informatie
          </Link>
          <a 
            href={mapsUrl}
            target="_blank"
            rel="nofollow noopener noreferrer"
            className="flex items-center text-gray-400 text-[13px] hover:text-gray-600 transition-colors group"
          >
            <MapPin className="w-4 h-4 text-blue-600 shrink-0 group-hover:text-blue-700" />
            <div className="ml-1.5">
              <span className="text-gray-600 group-hover:text-gray-700">{address}</span>
              <span className="mx-1 text-gray-400">•</span>
              <span className="font-medium text-blue-600 group-hover:text-blue-700">{city}</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};