import { SEO } from '../components/SEO';
import { Breadcrumbs } from '../components/Breadcrumbs';

export const Privacy = () => {
  return (
    <>
      <SEO 
        title="Privacy Policy | Baardtransplantatie.nl"
        description="Lees onze privacy policy om te begrijpen hoe wij uw gegevens verzamelen, gebruiken en beschermen."
        canonical="/privacy"
      />

      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12">
        <Breadcrumbs />
        
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Privacy Policy</h1>

        <div className="prose prose-lg max-w-none">
          <p className="text-lg text-gray-600 mb-8">
            Laatst bijgewerkt: {new Date().toLocaleDateString('nl-NL')}
          </p>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">1. Inleiding</h2>
            <p>
              Baardtransplantatie.nl respecteert de privacy van alle gebruikers van haar website en zorgt ervoor dat de persoonlijke informatie die u ons verschaft vertrouwelijk wordt behandeld.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">2. Gegevensverzameling</h2>
            <p>
              Wij verzamelen informatie uit verschillende bronnen:
            </p>
            <ul className="list-disc pl-6 mt-4">
              <li>Persoonlijke gegevens die u vrijwillig aan ons verstrekt, bijvoorbeeld wanneer u:
                <ul className="list-disc pl-6 mt-2">
                  <li>Contact met ons opneemt via het contactformulier</li>
                  <li>Zich aanmeldt voor onze nieuwsbrief</li>
                  <li>Een kliniek toevoegt aan ons platform</li>
                </ul>
              </li>
              <li>Publiek beschikbare informatie over klinieken en behandelingen, waaronder:
                <ul className="list-disc pl-6 mt-2">
                  <li>Openbare bedrijfsgegevens van klinieken</li>
                  <li>Publiek toegankelijke reviews en beoordelingen</li>
                  <li>Informatie van officiële websites en bronnen</li>
                </ul>
              </li>
            </ul>
            <p className="mt-4">
              Alle verzamelde informatie wordt zorgvuldig gecontroleerd en verwerkt volgens de geldende privacywetgeving.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">3. Gebruik van Gegevens</h2>
            <p>
              De verzamelde gegevens worden gebruikt voor:
            </p>
            <ul className="list-disc pl-6 mt-4">
              <li>Het beantwoorden van uw vragen en verzoeken</li>
              <li>Het verbeteren van onze dienstverlening</li>
              <li>Het versturen van relevante informatie (indien u zich hiervoor heeft aangemeld)</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">4. Cookies</h2>
            <p>
              Wij maken gebruik van cookies om de gebruikerservaring te verbeteren en analytische gegevens te verzamelen. U kunt cookies uitschakelen via uw browserinstellingen.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">5. Gegevensbeveiliging</h2>
            <p>
              Wij nemen passende beveiligingsmaatregelen om misbruik van en ongeautoriseerde toegang tot uw persoonsgegevens te beperken.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">6. Uw Rechten</h2>
            <p>
              U heeft het recht om:
            </p>
            <ul className="list-disc pl-6 mt-4">
              <li>Uw persoonsgegevens in te zien</li>
              <li>Uw persoonsgegevens te laten corrigeren</li>
              <li>Uw persoonsgegevens te laten verwijderen</li>
              <li>Bezwaar te maken tegen de verwerking van uw gegevens</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">7. Contact</h2>
            <p>
              Voor vragen over ons privacybeleid kunt u contact opnemen via:
            </p>
            <ul className="list-none mt-4">
              <li>Email: info@baardtransplantatie.nl</li>
              <li>Telefoon: +31658924427</li>
            </ul>
          </section>
        </div>
      </div>
    </>
  );
}; 